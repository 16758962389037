<template>
    <div class="main-content">
        <div class="container lfont">
            <v-row>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="6">
                    <div class="form pt-6" v-if="applicant_apply">
                        <h1 class="text-primary text-h5 text-center"><span class="lfont">ແບບຟອມຢືນຢັນ ແລະ
                                ເລືອກເວລາສຳພາດ</span>
                        </h1>
                        <hr>
                        <p>ຊື່ ແລະ ນາມສະກຸນ: <span class="text-primary">{{ applicant_apply.gender == 'male' ? 'ທ້າວ' : 'ນາງ'
                        }}
                                {{ applicant_apply.name }} {{ applicant_apply.surname }}</span></p>
                        <p>ເບີໂທ: <span class="text-primary">{{ applicant_apply.tel }}</span></p>
                        <p>ຕຳແໜ່ງສະໝັກ: <span class="text-primary">{{ applicant_apply.positions.name }}</span></p>

                        <label>ເລືອກເວລາສຳພາດ</label>
                        <v-radio-group v-model="select_time" column>
                            <v-radio :label="item.interview_date" :value="item.id" v-for="(item, index) in interview_dates"
                                class="text-primary"></v-radio>
                        </v-radio-group>
                        <hr>
                        <v-alert dense text type="success" v-if="success_confirm">
                            ທ່ານໄດ້ຢືນຢັນ ແລະ ເລືອກເວລາສຳພາດສຳເລັດ <strong>ໝາຍເຫດ:
                            </strong><span class="text-warning">ຖ້າທ່ານມີການປ່ຽນແປງເວລາແມ່ນໃຫ້ທ່ານເລືອກເວລາໃໝ່ ແລະ
                                ກົດຢືນຢັນໃໝ່</span>
                        </v-alert>
                        <v-btn class="ma-2 text-white lfont" color="primary" @click="confirm_interview">
                            ຢືນຢັນນັດສຳພາດ
                        </v-btn>
                    </div>

                </v-col>
            </v-row>

        </div>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
    props: {
        apply_job_id: {
            required: true
        },
        phone_number: {
            required: true
        },
    },
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            interview_dates: {},
            applicant_apply: null,
            select_time: "",
            success_confirm: false
        }
    },
    methods: {
        confirm_interview() {
            this.isLoading = true;
            this.success_confirm = false
            let items = {
                interview_date_id: this.select_time
            };
            this.$axios
                .post(`applicants/confirm/interview/${this.apply_job_id}`, items)
                .then((res) => {
                    this.isLoading = false;
                    if (res.status === 200) {
                        this.success_confirm = true
                        setTimeout(() => {
                            this.$notification.OpenNotification(
                                "ການສະໝັກງານ",
                                "ທ່ານສະໝັກສຳເລັດ",
                                "top-right",
                                "success",
                                3000,
                            );
                        }, 300);
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },
        fetch_apply_job() {
            this.isLoading = true;
            this.$axios
                .get("applicants/check/confirm/interview", {
                    params: {
                        apply_job_id: this.apply_job_id,
                        phone_number: this.phone_number
                    }
                })
                .then((res) => {
                    this.isLoading = false;
                    this.interview_dates = res.data.data.appointment.interview_dates;
                    this.applicant_apply = res.data.data.apply;

                    let select_times = this.interview_dates.filter(item => item.confirm_this === 1);
                    if (select_times.length > 0) {
                        this.select_time = select_times[0].id;
                        this.success_confirm = true;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    let obj = error.response.data.errors;
                    if (error.response.status === 422) {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    } else if (error.response.status === 404) {
                        this.$notification.ShowErrors("top-right", "danger", 3000, error.response.statusText);
                    }
                });
        }
    },
    created() {
        this.fetch_apply_job();
    },
}
</script>

<style lang="scss" scoped>
.main-content {
    background-color: rgb(230, 230, 230);
}

.form {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    min-height: 100vh;
}
</style>